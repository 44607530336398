<!--
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-17 10:21:49
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-06-08 09:47:43
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\views\alert\defect.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="defectContainer" ref="defectContainer">  
        <div class="left">
            <img :src="alarmData.photoUrl" alt="" ref="defectImg">
        </div>
        <div class="right" ref="right">
            <div class="details">
                <div class="item" v-for="(item, index) in initInfoArray" :key="index">
                    <span class="label">{{item.label}}</span>
                    <span class="content">{{item.content}}</span>    
                </div>
                <div class="item">
                    <span class="label">确认意见：</span>
                    <el-input class="content green-normal-input" v-model="alarmData.manualRemark" placeholder="请输入确认意见"></el-input>
                </div>
            </div>
            <div class="btnGroup">
                <button class="downloadImage kr-normal-button btn" @click="downloadOriginImage">下载原图</button>        
                <button class="downloadReport kr-normal-button btn" @click="downloadReport">下载报告</button>  
                <button class="confirm kr-normal-button btn" @click="confirm">确认</button>                      
            </div>
        </div>          
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { 
    getDefectInfo,
    getOriginImage,
    getReport,
    confirmAlarm
} from '../../http/alert'
import { handleTimeFormat } from '../../utils/js/tool'
export default {
    data() {
        return {
            /*
             * @Date 2022/06/07 13:51:47
             * 缺陷信息
             */
            alarmData: {
                photoUrl: '',
                manualRemark: '未审查',                                   
                manualReview: false,                                     
                lineName: '',
                message: '',
                position: '',
                robotName: '',
                towerName: ''
            },


            /*
             * @Date 2022/06/07 13:51:52
             * 初始化样式信息
             */
            defectContainer: null,
            rightNode: null,
            imgNode: null
        }
    },

    computed: {
        ...mapState(['warnId', 'warnImgName', 'warnReportName']),

        /*
         * @Date 2022/06/07 13:48:11
         * 告警信息
         */
        initInfoArray: function () {
            let initData = [{
                label: '拍摄位置：',
                content: `${this.alarmData.lineName} ${this.alarmData.towerName} ${this.alarmData.position}`
            },{
                label: '巡检机器人：',
                content: this.alarmData.robotName
            },{
                label: '检测时间：',
                content: handleTimeFormat((this.alarmData.photoUrl?.split('_'))[3]?.replace('.jpg', ''))
            },{
                label: '检测结果：',
                content: this.alarmData.message
            }, {
                label: '确认状态',
                content: this.confirmState
            }];

            return initData
        },


        /*
         * @Date 2022/06/07 13:48:30
         * 确认状态
         */
        confirmState: function() {
            return this.alarmData.manualReview ? '已确认' : '未确认'
        }


    },

    methods: {
        /**
         * @Author KR0288
         * @Date 2022/05/17 13:31:20
         * @Description 初始化数据
         */
        getInitData() {
            getDefectInfo({
                id: this.warnId
            }).then(res => {
                if(res.code == '000000') {
                    this.$message({
                        type: 'success',
                        message: res.mesg
                    });
                    console.log(res.data);
                    this.alarmData = res.data;
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data
                    })
                }
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/05/27 14:53:24
         * @Description 下载原图
         */
        downloadOriginImage() {
            getOriginImage({
                id: this.warnId
            }).then(res => {
                let blob = new Blob([res], { type: 'image/jpeg' })

                // 兼容 IE & EDGE
                if (window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, this.warnImgName);
                } else {
                    var link = document.createElement("a");
                    const url = window.URL || window.webkitURL || window.moxURL;
                    link.href = url.createObjectURL(blob);
                    link.download = this.warnImgName;
                    Promise.resolve(link.click()).then(res => {
                        this.$message({
                            type: 'success',
                            message: '下载成功'
                        });
                    }).catch(err => {
                        this.$message({
                            type: 'success',
                            message: err
                        });
                    });
                    url.revokeObjectURL(link.href);
                }
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/05/27 14:54:44
         * @Description 下载报告
         */
        downloadReport() {
            getReport({
                id: this.warnId
            }).then(res => {
                let blob = new Blob([res], { type: 'application/msword' })

                if (window.navigator.msSaveOrOpenBlob) {
                    // 兼容 IE & EDGE
                    navigator.msSaveBlob(blob, this.warnReportName);
                } else {
                    var link = document.createElement("a");
                    const url = window.URL || window.webkitURL || window.moxURL;
                    link.href = url.createObjectURL(blob);
                    link.download = this.warnReportName;
                    link.click();
                    url.revokeObjectURL(link.href);
                }
                this.$message({
                    type: 'success',
                    message: res.mesg
                });
            }).catch(err => {
                this.$message({
                    type: 'error',
                    message: err
                })
            })
        },

        /**
         * @Author KR0288
         * @Date 2022/05/27 13:25:02
         * @Description 管理员确认审查
         */
        confirm() {
            if(this.alarmData.manualRemark != '') {
                this.alarmData.manualReview = true;
                confirmAlarm({
                    id: this.warnId,
                    manualRemark: this.alarmData.manualRemark
                }).then(res => {
                    if(res.code == '000000') {
                        this.$message({
                            type: 'success',
                            message: '已确认'
                        });
                    }else {
                        this.$message({
                            type: 'error',
                            message: res.data
                        })
                    }
                }).catch(err => {
                    this.$message({
                        type: 'error',
                        message: err
                    })
                })             
            }else {
                this.$message({
                    type: 'error',
                    message: '请先填写确认意见!'
                });  
            }
        }
    },

    created() {
        this.getInitData();
    },

    updated() {
        let _this = this;

        // 1、初始化适应屏幕
        let mainContainer = _this.$refs.defectContainer;
        mainContainer.style.height = window.innerHeight + 'px';
        mainContainer.style.minHeight = '754px';


        // 2、右侧内容与图片等高
        let rightNode = _this.$refs.right;
        let imgNode = _this.$refs.defectImg;

        imgNode.onload = () => {
            rightNode.style.height = window.getComputedStyle(imgNode).height;
            console.log(window.getComputedStyle(imgNode).height);
        }

        window.onresize = () => {
            mainContainer.style.height = window.innerHeight + 'px';
            rightNode.style.height = window.getComputedStyle(imgNode).height;
        }
    },

    watch: {

    }
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
    .defectContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .1042rem;
        box-sizing: border-box;
        background: url('../../assets/imgs/common/背景.png') no-repeat center center;
        background-size: 104% 107.6%;
        .left {
            display: flex;
            align-items: center;
            width: 70%;
            img {
                width: 100%;
            }
        }

        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            width: 40%;
            box-sizing: border-box;
            color: white;
            .details {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                .item {
                    display: flex;
                    justify-content: space-around;
                    padding: .1042rem .1563rem;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: .0833rem;
                    .label {
                        display: inline-block;
                        text-align: left;
                        width: .5859rem;
                    }
                    .content {
                        flex: 1;
                        border-bottom: 1px solid white;
                    }
                }
            }
            .btnGroup {
                display: flex;
                justify-content: flex-start;
                padding-left: .1563rem;
                .downloadImage,
                .downloadReport,
                .confirm {
                    display: block;
                    padding: 0.07rem 0.14rem ;
                    margin-right: .0625rem;
                }
            }
        }
    }   
}

@media (max-width: 1199.99px) {
    .defectContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 667px;
        background: url('../../assets/imgs/common/mobile-bg.png') no-repeat center center;
        background-size: 100% 100%;
        padding: .8rem;
        box-sizing: border-box;
    .left {
        width: 100%;
        img {
            width: 100%;
        }
    }

    .right {
        flex: 1;
        box-sizing: border-box;
        .details {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin-bottom: .8rem;
            .item {
                display: flex;
                justify-content: space-around;
                padding: .4rem 0;
                width: 100%;
                box-sizing: border-box;
                font-size: .32rem;
                color: white;
                .label {
                    display: inline-block;
                    text-align: left;
                    width: 2.4rem;
                }
                .content {
                    flex: 1;
                    border-bottom: 1px solid white;
                }
            }
        }
        .btnGroup {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            box-sizing: border-box;
            .btn {
                margin-right: 12px;
                // width: 15vw;
                padding: .32rem;
                font-size: .32rem !important;
            }
        }
    }
}
}

</style>